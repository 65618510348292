<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="container">
    <div class="row">
      <div class="col-sm-12" style="text-align: justify">
        <div class="headline style-3">
          <h2>W celu ułatwienia wyceny można skorzystać z poniższego kalkulatora:</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div>
            <div class="row">
              <div class="tag col-sm-12 m-bottom">
                Odbiór dokumentów z siedziby klienta:
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3" style="min-height: 45px">
                <input
                  id="receiving-2-1"
                  v-model="receiving"
                  type="radio"
                  name="receiving"
                  value="yes"
                  class="regular-checkbox big-checkbox"
                >
                <label for="receiving-2-1" />tak
                <br>
              </div>
              <div class="col-sm-3" style="min-height: 45px">
                <input
                  id="receiving-2-2"
                  v-model="receiving"
                  type="radio"
                  name="receiving"
                  value="no"
                  class="regular-checkbox big-checkbox"
                  checked
                >
                <label for="receiving-2-2" />nie
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div>
            <div class="row">
              <div class="tag col-sm-12 m-bottom">
                Vat:
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3" style="min-height: 45px">
                <input
                  id="vat-2-1"
                  v-model="vat"
                  type="radio"
                  name="vat"
                  value="with"
                  class="regular-checkbox big-checkbox"
                >
                <label for="vat-2-1" />
                <nobr>czynny podatnik vat</nobr>
              </div>
              <div class="col-sm-3" style="min-height: 45px">
                <input
                  id="vat-2-2"
                  v-model="vat"
                  type="radio"
                  name="vat"
                  value="without"
                  class="regular-checkbox big-checkbox"
                  checked
                >
                <label for="vat-2-2" />
                <nobr>podatnik zwolniony z vat</nobr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="tag col-sm-12">
            Ilość dokumentów:
          </div>
        </div>
        <div>
          <div class="form-group">
            <div class="col-sm-5 calculator-float">
              <input
                id="pkpir"
                v-model="pkpir"
                type="number"
                name="pkpir"
                placeholder="Wpisz ilość dokumentów"
                value="0"
                min="0"
                class="form-control"
              >
            </div>
            <label for="pkpir" class="col-sm-5 control-label tag">PKPIR</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div class="form-group">
            <div class="col-sm-5 calculator-float">
              <input
                id="ryczalt"
                v-model="ryczalt"
                type="number"
                name="ryczalt"
                placeholder="Wpisz ilość dokumentów"
                value="0"
                min="0"
                class="form-control"
              >
            </div>
            <label for="ryczalt" class="col-sm-5 control-label tag">Ryczałt</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div class="form-group">
            <div class="col-sm-5 calculator-float">
              <input
                id="ksiega"
                v-model="ksiega"
                type="number"
                name="ksiega"
                placeholder="Wpisz ilość dokumentów"
                value="0"
                min="0"
                class="form-control"
              >
            </div>
            <label for="ksiega" class="col-sm-5 control-label tag">Księga handlowa*</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="tag col-sm-12">
            Ilość osób zatrudnionych na:
          </div>
        </div>
        <div>
          <div class="form-group">
            <div class="col-sm-5 calculator-float">
              <input
                id="UoP"
                v-model="uop"
                type="number"
                name="UoP"
                placeholder="ilość osób zatrudnionych na umowę o pracę"
                value="0"
                min="0"
                class="form-control"
              >
            </div>
            <label for="UoP" class="col-sm-5 control-label tag">umowę o pracę</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div class="form-group">
            <div class="col-sm-5 calculator-float">
              <input
                id="UC"
                v-model="uc"
                type="number"
                name="UC"
                placeholder="ilość osób zatrudnionych na umowę cywilnoprawną (umowa zlecenie / o dzieło)"
                value="0"
                min="0"
                class="form-control"
              >
            </div>
            <label for="UC" class="col-sm-5 control-label tag">umowę cywilnoprawną</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-top">
      <div class="col-sm-12">
        <h3 class="result">
          Cena:
          <span ref="result">00.00 zł</span>**
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div>
            <div class="row">
              <div class="tag col-sm-12 m-bottom">
                <p>Prześlij kalkulację – skontaktujemy się z Tobą.</p>
                <p><a href="mailto:biuro@biurostorno.pl">biuro@biurostorno.pl</a></p>
                <p><a href="mailto:storno@biurostorno.pl">storno@biurostorno.pl</a></p>
              </div>
            </div>
<!--            <div class="col-sm-6">-->
<!--              <form-->
<!--                id="calculator-form"-->
<!--                ref="calculator-form"-->
<!--                class="calculator-form"-->
<!--                name="calculator-form"-->
<!--                method="post"-->
<!--                novalidate="novalidate"-->
<!--                @submit.prevent="checkForm"-->
<!--              >-->
<!--                <div-->
<!--                  v-if="errors.length"-->
<!--                  class="error"-->
<!--                >-->
<!--                  <b>Wystąpił błąd:</b>-->
<!--                  <ul>-->
<!--                    <li-->
<!--                      v-for="(error, index) in errors"-->
<!--                      :key="index"-->
<!--                    >-->
<!--                      {{ error }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--                <fieldset>-->
<!--                  <div-->
<!--                    id="alert-area"-->
<!--                    ref="alert-area"-->
<!--                  />-->

<!--                  <input-->
<!--                    v-model="name"-->
<!--                    class="col-xs-12"-->
<!--                    type="text"-->
<!--                    name="name"-->
<!--                    placeholder="imię / nazwisko / nazwa firmy"-->
<!--                    required-->
<!--                  >-->

<!--                  <input-->
<!--                    v-model="phone"-->
<!--                    class="col-xs-12"-->
<!--                    type="text"-->
<!--                    name="phone"-->
<!--                    placeholder="telefon"-->
<!--                  >-->

<!--                  <input-->
<!--                    v-model="email"-->
<!--                    class="col-xs-12"-->
<!--                    type="email"-->
<!--                    name="email"-->
<!--                    placeholder="email"-->
<!--                  >-->

<!--                  <textarea-->
<!--                    v-model="uwagi"-->
<!--                    class="col-xs-12"-->
<!--                    name="uwagi"-->
<!--                    rows="8"-->
<!--                    cols="25"-->
<!--                    placeholder="uwagi"-->
<!--                  />-->
<!--                  <input type="hidden" name="count" :value="hiddenCount">-->
<!--                  <input type="hidden" name="pkdir" :value="pkpir">-->
<!--                  <input type="hidden" name="ryczalt" :value="ryczalt">-->
<!--                  <input type="hidden" name="ksiega" :value="ksiega">-->
<!--                  <input type="hidden" name="UoP" :value="uop">-->
<!--                  <input type="hidden" name="Uc" :value="uc">-->
<!--                  <input type="hidden" name="receiving" :value="receiving">-->
<!--                  <input type="hidden" name="vat" :value="vat">-->
<!--                </fieldset>-->
<!--                &lt;!&ndash;<filedset>&ndash;&gt;-->
<!--                &lt;!&ndash;<recaptcha&ndash;&gt;-->
<!--                &lt;!&ndash;@error="onError"&ndash;&gt;-->
<!--                &lt;!&ndash;@success="onSuccess"&ndash;&gt;-->
<!--                &lt;!&ndash;@expired="onExpired"&ndash;&gt;-->
<!--                &lt;!&ndash;/>&ndash;&gt;-->
<!--                &lt;!&ndash;</filedset>&ndash;&gt;-->
<!--                <fieldset>-->
<!--                  <input-->
<!--                    id="submit"-->
<!--                    class="btn btn-default"-->
<!--                    type="submit"-->
<!--                    name="submit"-->
<!--                    value="Wyślij formularz"-->
<!--                  >-->
<!--                </fieldset>-->
<!--              </form>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-html="infoTitle" />
    <div class="row" v-html="info" />
    <div class="row m-top" v-html="footer" />
  </div>
</template>
<script>
import { texts } from './texts'
const axios = require('axios')
// let $ = false

export default {
  name: 'Calculator',
  mixins: [ texts ],
  data () {
    return {
      data: {
        pkpir: {
          '0': [ '0', '0' ],
          '30': ['200', '250'],
          '60': ['250', '350'],
          '100': ['310', '480'],
          '150': ['440', '600'],
          '200': ['560', '750'],
          '250': ['690', '900'],
          '300': ['750', '1050'],
          '350': ['810', '1130'],
          '400': ['880', '1200'],
          '450': ['940', '1280'],
          '500': ['1000', '1380'],
          '600': ['1130', '1580'],
          '700': ['1250', '1800'],
          '800': ['1380', '1950'],
          '900': ['1500', '2100'],
          '1000': ['1650', '2250'],
          '> 1000': ['1,65', '2,25']
        },
        ryczalt: {
          '0': ['0', '0'],
          '30': ['150', '200'],
          '60': ['190', '280'],
          '100': ['240', '360'],
          '150': ['310', '510'],
          '200': ['380', '660'],
          '250': ['460', '800'],
          '300': ['500', '900'],
          '350': ['540', '970'],
          '400': ['590', '1050'],
          '450': ['630', '1130'],
          '500': ['680', '1200'],
          '600': ['750', '1350'],
          '700': ['830', '1500'],
          '800': ['900', '1650'],
          '900': ['980', '1800'],
          '1000': ['1050', '1950'],
          '> 1000': ['1,05', '1,95']
        },
        ksiega: {
          '0': ['0', '0'],
          '30': ['500', '750'],
          '60': ['650', '900'],
          '100': ['750', '1050'],
          '150': ['900', '1200'],
          '200': ['1000', '1350'],
          '250': ['1150', '1500'],
          '300': ['1250', '1650'],
          '350': ['1400', '1800'],
          '400': ['1500', '1950'],
          '450': ['1650', '2100'],
          '500': ['1750', '2250'],
          '600': ['1900', '2350'],
          '700': ['2000', '2500'],
          '800': ['2150', '2650'],
          '900': ['2250', '2800'],
          '1000': ['2400', '2950'],
          '> 1000': ['2,40', '2,95']
        },
        vat: 1,
        receiving: 1
      },
      uc: 0,
      hiddenCount: 0,
      pkpir: 0,
      ryczalt: 0,
      ksiega: 0,
      uop: 0,
      vat: 'with',
      receiving: 'yes',
      uopPrize: 70, // osoba zatrudniona na umowę o pracę: kwota za osobę
      ucPrize: 50, // osoba zatrudniona na umowę cywilnoprawną: kwota za osobę
      errors: [],
      uwagi: '',
      email: '',
      phone: '',
      name: ''
    }
  },
  watch: {
    pkpir () {
      this.pkpir = this.checkNumber(this.pkpir)
      this.calculate()
    },
    ryczalt () {
      this.ryczalt = this.checkNumber(this.ryczalt)
      this.calculate()
    },
    ksiega () {
      this.ksiega = this.checkNumber(this.ksiega)
      this.calculate()
    },
    // liczba osób zatrudnionych na umowę o pracę
    uop () {
      this.uop = this.checkNumber(this.uop)
      this.calculate()
    },
    // liczba osób zatrudnionych na umowę cywilnoprawną
    uc () {
      this.uc = this.checkNumber(this.uc)
      this.calculate()
    },
    vat () {
      this.data.vat = this.vat === 'with' ? 1 : 0
      this.calculate()
    },
    receiving () {
      this.data.receiving = this.receiving === 'yes' ? 1 : 0
      this.calculate()
    }
  },
  mounted () {

  },
  methods: {
    onError (error) {
      // eslint-disable-next-line no-console
      console.log('Error happened:', error)
    },
    onSuccess (token) {
      // eslint-disable-next-line no-console
      console.log('Succeeded:', token)
      axios({
        method: 'post',
        url: '/api/calculator',
        data: {
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
          'uwagi': this.uwagi,
          'count': this.hiddenCount,
          'pkdir': this.pkpir,
          'ryczalt': this.ryczalt,
          'ksiega': this.ksiega,
          'UoP': this.uop,
          'Uc': this.uc,
          'receiving': this.receiving === 'yes' ? 'tak' : 'nie',
          'vat': this.vat === 'with' ? 'tak' : 'nie'
        }
      })
        .then((response) => {
          this.errors = []

          if (response && response.data.message === 'OK') {
            this.$refs['alert-area'].innerHTML = '<div class="alert alert-success">Twoja wiadomość została wysłana!</div>'
            this.name = ''
            this.phone = ''
            this.email = ''
            this.uwagi = ''
          } else {
            this.errors.push('Proszę spróbować wysłać formularz jeszcze raz lub skontaktować się biurem przez adres email lub telefon.')
          }
        })
        .catch((e, response) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          if (e.response.status === 422) {
            this.errors.push('Wystąpił błąd. Proszę sprawdzić czy dane zostały prawidłowo uzupełnione.')
          } else {
            this.errors.push('Proszę spróbować wysłać formularz jeszcze raz lub skontaktować się biurem przez adres email lub telefon.')
          }
        })
    },
    onExpired () {
      // eslint-disable-next-line no-console
      console.log('Expired')
    },
    checkForm (e) {
      e.preventDefault()
      this.calculate()

      this.errors = []
      if (!this.name) {
        this.errors.push('Proszę uzupełnić imię / nazwisko / nazwa firmy')
      }
      if (!this.email) {
        this.errors.push('Proszę uzupełnić email')
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Proszę wprowadzić poprawny adres email')
      }

      if (!this.errors.length) {
        this.sendMail()
      }
    },
    sendMail () {
      axios({
        method: 'post',
        url: '/api/calculator',
        data: {
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
          'uwagi': this.uwagi,
          'count': this.hiddenCount,
          'pkdir': this.pkpir,
          'ryczalt': this.ryczalt,
          'ksiega': this.ksiega,
          'UoP': this.uop,
          'Uc': this.uc,
          'receiving': this.receiving === 'yes' ? 'tak' : 'nie',
          'vat': this.vat === 'with' ? 'tak' : 'nie'
        }
      })
        .then((response) => {
          this.errors = []

          if (response && response.data.message === 'OK') {
            this.$refs['alert-area'].innerHTML = '<div class="alert alert-success">Twoja wiadomość została wysłana!</div>'
            this.name = ''
            this.phone = ''
            this.email = ''
            this.uwagi = ''
          } else {
            this.errors.push('Proszę spróbować wysłać formularz jeszcze raz lub skontaktować się biurem przez adres email lub telefon.')
          }
        })
        .catch((e, response) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          if (e.response.status === 422) {
            this.errors.push('Wystąpił błąd. Proszę sprawdzić czy dane zostały prawidłowo uzupełnione.')
          } else {
            this.errors.push('Proszę spróbować wysłać formularz jeszcze raz lub skontaktować się biurem przez adres email lub telefon.')
          }
        })
    },
    // async sendMail () {
    //   try {
    //     const token = await this.$recaptcha.getResponse()
    //     console.log('ReCaptcha token:', token)
    //     await this.$recaptcha.reset()
    //   } catch (error) {
    //     console.log('Login error:', error)
    //   }
    // },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    checkNumber (inputVal) {
      inputVal = parseInt(inputVal)
      if ((inputVal < 0) || !(Number.isInteger(inputVal))) {
        return 0
      }
      return inputVal
    },
    calculate () {
      let sum = 0

      // Obsługa kadrowa płacowa:
      let u = (this.uop * this.uopPrize) + (this.uc * this.ucPrize)

      for (const index in this.data.pkpir) {
        const value = this.data.pkpir[index]
        if (this.pkpir > 1000) {
          sum += parseFloat(this.data.pkpir['1000'][this.data.vat]) + ((this.pkpir - 1000) * parseFloat(this.data.pkpir['> 1000'][this.data.vat].replace(',', '.')))
          break
        }
        if (this.pkpir <= index) {
          sum += parseFloat(value[this.data.vat].replace(',', '.'))
          break
        }
      }

      for (const index in this.data.ryczalt) {
        const value = this.data.ryczalt[index]
        if (this.ryczalt > 1000) {
          sum += parseFloat(this.data.ryczalt['1000'][this.data.vat]) + ((this.ryczalt - 1000) * parseFloat(this.data.ryczalt['> 1000'][this.data.vat].replace(',', '.')))
          break
        }
        if (this.ryczalt <= index) {
          sum += parseFloat(value[this.data.vat].replace(',', '.'))
          break
        }
      }

      for (const index in this.data.ksiega) {
        const value = this.data.ksiega[index]
        if (this.ksiega > 1000) {
          sum += parseFloat(this.data.ksiega['1000'][this.data.vat]) + ((this.ksiega - 1000) * parseFloat(this.data.ksiega['> 1000'][this.data.vat].replace(',', '.')))
          break
        }
        if (this.ksiega <= index) {
          sum += parseFloat(value[this.data.vat].replace(',', '.'))
          break
        }
      }

      // Pomniejszenie obsługi kadrowo płacowej
      if (sum <= 250) {
        // u = u
      } else if (sum <= 1000) {
        u = u * 95 / 100 // minus 5%
      } else if (sum <= 2000) {
        u = u * 90 / 100// minus 10%
      } else if (sum <= 3000) {
        u = u * 85 / 100// minus 15%
      } else if (sum <= 4000) {
        u = u * 80 / 100// minus 20%
      } else if (sum <= 5000) {
        u = u * 75 / 100// minus 25%
      } else if (sum > 5000) {
        u = u * 70 / 100// minus 30%
      }
      sum = sum + parseFloat(u)

      // Odbiór dokumentów z siedziby klienta
      if (this.data.receiving) {
        // Jeżeli kwota za wszystkie usługi (księgowe i kadrowe, razem) jest większ od 0zł
        if (sum > 0) {
          if (sum <= 500) {
            sum += 100
          } else if (sum <= 1000) {
            sum += 80
          } else if (sum <= 2000) {
            sum += 50
          } else {
            sum += 30
          }
        }
      }

      this.$refs.result.textContent = sum.toFixed(2) + ' zł'
      this.hiddenCount = sum.toFixed(2) + ' zł'
    }
  }
}
</script>
