export const texts = {
  data () {
    return {
      footer:
        `<div class="col-sm-12">
          <small>
            <strong>*</strong> Przez dokument należy rozumieć pozycję księgową, czyli jedną fakturę, jeden przelew, pozycję raportu kasowego, polecenie księgowania.
            <br>
            <strong>**</strong> Wyliczona kwota, to kwota netto do której należy doliczyć podatek VAT w wysokości 23%. Powyższa oferta ma charakter informacyjny i jest zaproszeniem do negocjacji, niniejsza oferta nie stanowi oferty handlowej w rozumieniu art. 66 § 1 Kodeksu cywilnego oraz innych właściwych przepisów prawnych.
          </small>
        </div>`,
      info:
      `<div class="col-sm-6">
        <small>
          Usługi księgowe:
          <br>- prowadzenie ksiąg, ewidencji vat, ewidencji środków trwałych i wyposażenia,
          <br>- sporządzanie deklaracji i zeznań podatkowych w tym zeznania rocznego,
          <br>- konsultacje i doradztwo w sprawach prowadzenia firmy,
          <br>- doradztwo podatkowe,
          <br>- reprezentowanie klienta przed Urzędem Skarbowym,
          <br>- naliczanie składek ZUS oraz sporządzenie dokumentów do ZUS dotyczących przedsiębiorcy.
        </small>
      </div>
      <div class="col-sm-6">
        <small>
          Obsługa kadrowa:
          <br>- przygotowanie list płac i rachunków do umów zleceń,
          <br>- przygotowanie wszystkich dokumentów do ZUS,
          <br>- sporządzenie umów o pracę i umów zleceń,
          <br>- przygotowanie świadectw pracy,
          <br>- prowadzenie kart czasu pracy na podstawie list obecności,
          <br>- sporządzanie dokumentacji do PFRON,
          <br>- przygotowanie korespondencji dotyczącej pracowników (np. pism do komorników),
          <br>- sporządzanie i wysłanie do rzędów skarbowych informacji (PIT-11) i deklaracji (PIT4R, PIT8AR),
          <br>- reprezentowanie klienta przed Urzędem Skarbowym oraz ZUS,
          <br>- doradztwo dotyczące spraw kadrowych oraz ZUS.
        </small>
      </div>`,
      infoTitle:
        `<div class="col-sm-12">
          <small>
            <strong>Cena zawiera bez dodatkowych opłat:</strong>
          </small>
        </div>`
    }
  }
}
