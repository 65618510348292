<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Cennik</h2>
          </div>
          <img src="images/c.jpg" width="237" height="237" style="float:right">
          <p>
            Ceny Naszych usług zależą od wielu czynników m.in: rodzaju
            ewidencji księgowych, ilości dokumentów, wielkości zatrudnienia,
            specyfiki działalności firmy.
          </p>
          <p>
            Ceny ustalane są indywidualnie z klientem, oraz podlegają
            negocjacjom.
          </p>
          <p>
            W celu sporządzenia dla Państwa orientacyjnej wyceny kosztów
            usług księgowych prosimy o przesłanie e-maila na adres:
          </p>
          <p>
            <a href="mailto:storno@biurostorno.pl">storno@biurostorno.pl</a>
          </p>
          <p>zawierającego następujące dane:</p>
          <ul>
            <li>rodzaj prowadzonej działalności (np. handel, produkcja, usługi),</li>
            <li>
              zasady rozliczania (księgi handlowe, księgi przychodów i
              rozchodów, ryczałt),
            </li>
            <li>
              ilość dokumentów księgowych w miesiącu (faktury sprzedaży,
              zakupu, wyciągi bankowe, dokumenty kasowe),
            </li>
            <li>ilość zatrudnionych pracowników,</li>
            <li>indywidualne wymagania Klienta.</li>
          </ul>
          <p>
            Istnieje również możliwość bezpośredniego spotkania zarówno w
            Państwa siedzibie jak i u nas w biurze (Poznań, ul. Główna 46/1 lub
            Poznań, os. Dębina 11/7).
          </p>
          <p>
            Takie spotkanie nie wiąże się dla Państwa z żadnymi kosztami, a
            my ze swojej strony zapewniamy, że wszelkie informacje uzyskane od
            Państwa są objęte tajemnicą zawodową niezależnie od tego czy takie
            spotkanie zakończy się podpisaniem umowy.
          </p>
        </div>
      </div>
    </div>
    <calculator />
  </div>
</template>

<script>
import Calculator from '../components/Calculator/Calculator.vue'
export default {
  head: {
    title: 'Cennik - Biuro rachunkowe Poznań "STORNO"'
  },
  components: {
    Calculator
  }
}
</script>
